import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/renderer/router.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer/Chatbox.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Headers/RenewalCheckoutHeader.ui.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Headers/Session.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/arrowNext.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/arrowPrev.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/bandera.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/browserEdge.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/cardAmex.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/cardMaster.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/cardVisa.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/flag-argentina.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoConectividad.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoCustomer.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoEntretenimiento.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoEspecificacion.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoIncluye.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconCart.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconCheck.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconClub.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconEntretenimiento.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconHogar.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconIOT.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconMovil.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconMusica.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconPromociones.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconQuestion.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconSad.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconUserData.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/iconVideo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoPortability.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/icoResumen.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/logoIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/myAddress.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/myOrders.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/myProfile.svg");
;
import(/* webpackMode: "eager" */ "/app/src/helpers/svg/xclose.svg");
